import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import Sidebar from '../../components/student/dashboard/common/Sidebar';
import SectionBox from '../../components/student/dashboard/common/SectionBox';
import ButtonBox from '../../components/student/common/ButtonBox';
import TherapistList from '../../components/student/dashboard/therapist/List';
import ScheduleList from '../../components/student/dashboard/schedule/List';
import StudentAccountManagerList from '../../components/student/dashboard/accountmanager/List';
import SubmitConsentForm from '../../components/student/dashboard/common/ConsentBox';
import IntakeGenEdForm from '../../components/student/dashboard/common/IntakeGenEdForm';
import TLModal from '../../components/student/common/Modal';
import { dashboard, genEdAvailed, intakeAvailed } from '../../services/api/student/authService';
import AccountManagerSkeleton from '../../components/student/skeleton/AccountManagerSkeleton';
import ScheduleSkeleton from '../../components/student/skeleton/ScheduleSkeleton';
import StudentConsentForm from '../../components/student/common/ConsentForm';
import { GetActivity } from '../../utils/student';
import { APP_NAME } from '../../utils/constants/AppConstant';
import { sendAmplitudeData, setAmplitudeUserProperties } from '../../utils/amplitude';

const Home = () => {
  const [getStudentDetail, setGetStudentDetail] = useState(false);
  const [therapists, setTherapists] = useState([]);
  const [accountManager, setAccountManager] = useState([]);
  const [schedulesList, setSchedulesList] = useState([]);
  const [schedulesMessage, setSchedulesMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [futureSchedule, setFutureSchedule] = useState(false);
  const [genEdSection, setGenEdSection] = useState(false);
  const [genEdSchoolName, setGenEdSchoolName] = useState('');
  const [genEdStudentName, setGenEdStudentName] = useState('');
  const [genEdStaffCounseling, setGenEdStaffCounseling] = useState(false);
  const [genEdMessage, setGenEdMessage] = useState('');
  const [intakeSection, setIntakeSection] = useState(false);
  const [intakeMessage, setIntakeMessage] = useState('');

  const user = useSelector((state) => state.user[0]);
  const isStaffUser = user?.isStaffUser;

  const studentSubmit = async () => {
    try {
      const res = await dashboard();

      const { account_managers, schedules, assigned_therapists, future_schedule } = res.data.data;
      if (res?.status === 200) {
        setLoading(false);
      }
      setTherapists(assigned_therapists);
      setAccountManager(account_managers);
      setSchedulesList(schedules);
      setFutureSchedule(future_schedule);
      setSchedulesMessage(res.data.message);
    } catch (_) {
      //
    }
  };

  const genEd = async () => {
    try {
      const res = await genEdAvailed();
      if (res.status === 200) {
        const { data } = res.data;
        if (data.availed) {
          setGenEdSection(true);
          setGenEdSchoolName(data.student.school.name);
          setGenEdStudentName(data.student.name);
          setGenEdMessage(res.data.message);
          setGenEdStaffCounseling(data.gened_staff_counseling);
        }
      }
    } catch (_) {
      //
    }
  };

  const intake = async () => {
    const res = await intakeAvailed();
    if (res.status === 200) {
      const { data } = res.data;
      if (data.availed) {
        setIntakeSection(true);
        setIntakeMessage(res.data?.message);
      } else {
        setIntakeSection(false);
      }
    }
  };

  useEffect(() => {
    studentSubmit();
    genEd();
    intake();
    GetActivity(window.location.pathname);
    setAmplitudeUserProperties({
      'User Type':
        // eslint-disable-next-line no-nested-ternary
        user.type === 1 ? 'Student' : user.type === 6 ? 'FLVS' : user.type === 3 || user.type === 5 ? 'School' : '',
    });
    sendAmplitudeData('Home');
  }, [user.type]);

  function userDetailsModal() {
    return (
      <TLModal
        show={getStudentDetail}
        title="Add Required Information"
        onHide={() => setGetStudentDetail(false)}
        data={<StudentConsentForm setGetStudentDetail={setGetStudentDetail} />}
        size="md"
      />
    );
  }

  const updateFromChild = () => {
    setGenEdSection(false);
  };

  function genEdSectiondesign() {
    if (genEdSection) {
      return (
        <SectionBox
          Title="IMPORTANT: Submit Consent Form"
          Icon="alert-triangle"
          IconColor="bg-green"
          HomeData={
            <SubmitConsentForm
              studentname={genEdStudentName}
              schoolname={genEdSchoolName}
              message={genEdMessage}
              updateParent={updateFromChild}
              staffCounseling={genEdStaffCounseling}
            />
          }
        />
      );
    }
  }

  function intakeGenEdSectionDesign() {
    if (intakeSection) {
      return (
        <SectionBox
          Title="IMPORTANT: Submit Intake Form"
          Icon="alert-triangle"
          IconColor="bg-green"
          HomeData={
            <IntakeGenEdForm
              message={intakeMessage}
              onSuccess={() => {
                setIntakeSection(false);
              }}
            />
          }
        />
      );
    }
  }

  const schedulecomponent = loading ? (
    <ScheduleSkeleton />
  ) : (
    <ScheduleList message={schedulesMessage} data={schedulesList} future_schedule={futureSchedule} />
  );
  const therapistcomponent = loading ? <AccountManagerSkeleton /> : <TherapistList data={therapists} />;
  const accountmanagercomponent = loading ? (
    <AccountManagerSkeleton />
  ) : (
    <StudentAccountManagerList data={accountManager} />
  );
  return (
    <>
      <Helmet title={`Home - Student -  ${APP_NAME}`}>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container>
        <Row>
          {!isStaffUser ? userDetailsModal() : null}
          <Col lg={12} xl={9}>
            {intakeGenEdSectionDesign()}
            {genEdSectiondesign()}
            <SectionBox Title=" My Schedule" Icon="video" Button={<ButtonBox />} HomeData={schedulecomponent} />
            <SectionBox Title=" My Therapist" Icon="user" HomeData={therapistcomponent} />
            <SectionBox Title=" My Account Manager" Icon="user" HomeData={accountmanagercomponent} />
          </Col>
          <Col sm={12} md={5} lg={4} xl={3} className="mg-lg-t-0 mg-sm-t-0">
            <Sidebar getStudentDetail={(a) => setGetStudentDetail(a)} />
          </Col>
        </Row>
        <TLModal />
      </Container>
    </>
  );
};

export default Home;
