import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import TLTooltip from './TL-Tooltip';

function TLInputbox({
  label,
  placeholder,
  type = 'text',
  onChange,
  id,
  labelClassName = '',
  className = '',
  iconClassName = '',
  required = false,
  tooltip = '',
  error = '',
  onBlur,
  onFocus,
  disabled,
  defaultValue,
  onSearchClick,
  labelChild,
  labelChildClassName = '',
  value,
  showIcon = 'eye',
  hideIcon = 'eye-off',
  inputRef,
  min,
  max,
  step,
  parentDivClass,
}) {
  const [showPassword, setShowPassword] = useState(true);

  const handleWheel = (event) => {
    event.target.blur();
    setTimeout(() => event.target.focus(), 0);
  };

  return (
    <div className={parentDivClass}>
      {label && id ? (
        <div className={`${labelChild ? 'd-flex justify-content-between' : 'd-flex align-items-start'}`}>
          <label
            htmlFor={id}
            className={classNames(labelClassName || 'tx-interui tx-12 tx-uppercase tx-medium', {
              'input-required': required,
            })}
          >
            {label}
          </label>
          {labelChild && (
            <Link to={labelChild} className={labelChildClassName}>
              Forgot Password?
            </Link>
          )}
          {tooltip && (
            <div className="mg-l-4">
              <TLTooltip tooltip={tooltip} className="mg-b-10" />
            </div>
          )}
        </div>
      ) : null}

      <div className="pos-relative">
        <input
          onWheel={handleWheel}
          ref={inputRef}
          onChange={onChange}
          id={id}
          className={classNames(
            `tl-border-color-C0CCDA form-control tl-input-placeholder-tx-12  ${className} ${
              type === 'password' || type === 'search' ? 'tl-input-action pd-r-35' : ''
            }
          `,
            {
              'bd-danger': error,
              'tl-input-password': type === 'password',
            }
          )}
          placeholder={placeholder}
          type={!showPassword ? 'text' : type}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          required={required}
          min={min}
          max={max}
          step={step}
        />
        {type === 'password' && (
          <span
            className="input-icon-button pos-absolute tl-cursor-pointer wd-35 d-flex align-items-center t-0 r-0 ht-100p justify-content-center"
            onClick={() => setShowPassword(!showPassword)}
          >
            <FeatherIcon
              icon={showPassword ? hideIcon : showIcon}
              className={`tl-eye-icon ${iconClassName || 'wd-20 ht-20 tl-color-8392A5'}`}
            />
          </span>
        )}

        {type === 'search' && (
          <span
            className="input-icon-button pos-absolute tl-cursor-pointer wd-35 d-flex align-items-center t-0 r-0 ht-100p justify-content-center"
            onClick={() => onSearchClick && onSearchClick()}
          >
            <FeatherIcon icon="search" className={`tl-eye-icon ${iconClassName || 'wd-20 ht-20 tl-color-8392A5'}`} />
          </span>
        )}
      </div>
      {error && <div className="text-danger tx-12">{error}</div>}
    </div>
  );
}

export default TLInputbox;
