import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import TLModal from '../../common/Modal';
import StudentGenEdConsent from '../../common/GenEdConsent';

const SubmitConsentForm = ({ studentname, schoolname, message, updateParent, staffCounseling }) => {
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [language, setLanguage] = useState('en');

  const modalHide = () => {
    setModalShow(false);
    setSuccess(false);
    if (success) {
      updateParent();
    }
  };

  return (
    <>
      <Card.Text className="mg-b-15 text-center tx-bold tx-20 d-block">{message}</Card.Text>
      <Card.Text className="btn-box text-center">
        <Button className="btn btn-outline-yellow" onClick={() => setModalShow(true)}>
          Submit Consent Form
        </Button>
      </Card.Text>
      <TLModal
        show={modalShow}
        onHide={modalHide}
        title="Gen Ed Consent"
        headerContent={
          success ? null : (
            <div className="custom-control custom-switch pos-relative language-selector-wrapper">
              <input
                type="checkbox"
                checked={language !== 'en'}
                className="custom-control-input"
                id="language-selector"
                onChange={() => setLanguage((prev) => (prev === 'en' ? 'sp' : 'en'))}
              />
              <label className="custom-control-label tx-medium mg-l-65" htmlFor="language-selector">
                <span>Spanish</span>
                <span
                  className="pos-absolute"
                  style={{
                    left: '-96px',
                  }}
                >
                  English
                </span>
              </label>
            </div>
          )
        }
        data={
          <StudentGenEdConsent
            modalHide={modalHide}
            language={language}
            studentname={studentname}
            schoolname={schoolname}
            updateParent={updateParent}
            staffCounseling={staffCounseling}
          />
        }
        size="lg"
      />
    </>
  );
};

export default SubmitConsentForm;
