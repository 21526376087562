import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Datatable from '../common/Datatable';
import { schoolHomePage } from '../../../services/api/student/authService';

const SchoolHomeTable = ({ handle }) => {
  const [todayDate, setTodayDate] = useState('');

  useEffect(() => {
    setTodayDate(moment().format('DD MMMM YYYY')); // Format today's date
  }, []);

  return (
    <>
      <div className="text-right mg-b-20 d-none">
        <Link className="tx-14 tx-md-16 tx-color-03" onClick={handle}>
          <FeatherIcon icon="arrow-left" className="mg-r-5" />
          Switch Back To Old Theme
        </Link>
      </div>
      <Card className="mg-b-20 mg-lg-b-25">
        <Card.Header className="pd-y-10 pd-x-15 d-flex flex-wrap align-items-center justify-content-between bg-gray-100">
          <Card.Text className="tx-bold mg-b-0 d-flex align-items-center">
            <span className="wd-30 ht-30 wd-lg-40 ht-lg-40 bg-green tx-white d-inline-block pd-6 text-center rounded mg-r-10 section-icon">
              <FeatherIcon icon="video" />
            </span>
            <span className="tx-14 tx-md-14 tx-lg-16">Today's Schedules</span>
          </Card.Text>
          {todayDate && <p className="tx-14 tx-bold m-0">{todayDate}</p>}
        </Card.Header>
        <Card.Body className="pd-15 pd-lg-15">
          <Datatable
            noDataMessage="You don't have any schedules"
            apiMethod={schoolHomePage}
            searchBar={false}
            exportButton={false}
            skeleton={{
              col: 7,
            }}
          />
        </Card.Body>
      </Card>
    </>
  );
};
export default SchoolHomeTable;
