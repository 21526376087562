import { API_VERSION } from '../../utils/constants/AppConstant';
import http from './httpService';

export function login() {
  return http.get('/login');
}

export function getUser(id) {
  return http.get(`/user/${id}`);
}

export function loginUser(data) {
  return http.post(`/oauth/token`, data);
}

export function logoutUser(data) {
  return http.post(`/oauth/logout`, data);
}

export const getUserDetails = (data) => http.get(`/oauth/user`, data);

export const getNavigation = (data) => http.get(`/${API_VERSION}/navigation`, data);

export const changePassword = (data) => http.post(`${API_VERSION}/change-password`, data);
export const forgotPassword = (data) => http.post(`${API_VERSION}/password/forgot`, data);
export const resetPassword = (data) => http.post(`${API_VERSION}/password/reset`, data);

export const getGuidelines = ({ id, params = '' }) => http.get(`${API_VERSION}/guidelines/${id}?${params}`);

export const getFaqs = () => http.get(`${API_VERSION}/faqs?fields[faqs]=faq_question,dt&sort=id,created_at`);

export const getFaq = (id) => http.get(`${API_VERSION}/faqs/${id}?fields[faqs]=faq_answer`);

export const logFaqSearch = (data) => http.post(`${API_VERSION}/faqs/search-history`, data);

export const getExternalSystemCredentials = (params) =>
  http.get(`${API_VERSION}/external-system-credentials?${params}`);

export const externalSystemsList = () => http.get(`${API_VERSION}/external-systems?filter[status]=1&all=true`);

export const addExternalSystemCredentials = (data) => http.post(`${API_VERSION}/external-system-credentials`, data);

export const getSingleExternalSystemCredential = (id) => http.get(`${API_VERSION}/external-system-credentials/${id}`);

export const updateExternalSystemCredentials = (id, data) =>
  http.put(`${API_VERSION}/external-system-credentials/${id}`, data);

export const deleteExternalSystemCredentials = (id) => http.delete(`${API_VERSION}/external-system-credentials/${id}`);

export const getToolsDetails = (id, params = '') => http.get(`${API_VERSION}/tools/${id}?${params}`);

export const logToolSearch = (data) => http.post(`${API_VERSION}/tools/store-search`, data);

export const getSessionDocuments = (params) => http.get(`${API_VERSION}/sessions/documents/list?${params}`);

export const uploadSessionDocument = (data) => http.post(`${API_VERSION}/sessions/documents/upload`, data);

export const newsletter = (params = '') => http.get(`${API_VERSION}/newsletters?${params}`);

export const getSessions = (params = '') => http.get(`${API_VERSION}/sessions/?${params}`);

export const getSession = ({ id, params = '' }) => http.get(`${API_VERSION}/sessions/${id}?${params}`);

export const getSchedule = ({ id, params = '' }) => http.get(`${API_VERSION}/schedules/${id}?${params}`);
export const flvsSchedule = (params) => http.get(`${API_VERSION}/schedules?${params}`);
export const getUserInfo = ({ id, params = '' }) => http.get(`${API_VERSION}/therapists/${id}?${params}`);

export const deleteSession = (id) => http.delete(`${API_VERSION}/sessions/${id}`);

export const deleteDocument = ({ data }) => http.post(`${API_VERSION}/sessions/documents/delete`, data);

export const getServices = ({ params = '' }) => http.get(`${API_VERSION}/services?${params}`);

export const getGoalCategories = ({ params = '', id }) => http.get(`${API_VERSION}/services/goals/${id}/?${params}`);

export const getHrDocuments = ({ params = '' }) => http.get(`${API_VERSION}/hr-document-type?${params}`);

export const getStates = ({ params = '' }) => http.get(`${API_VERSION}/states?${params}`);

export const getRace = ({ params = '' }) => http.get(`${API_VERSION}/races?${params}`);

export const getNote1 = ({ params = '' }) => http.get(`${API_VERSION}/notes?${params}`);

export const addStudent = ({ data }) => http.post(`${API_VERSION}/students`, data);

export const checkClashValidation = ({ params = '' }) =>
  http.get(`${API_VERSION}/sessions/validate-indirect-time?${params}`);

export const uploadOrAddSession = ({ data }) => http.post(`${API_VERSION}/sessions`, data);

export const getTodos = ({ params = '' }) => http.get(`${API_VERSION}/todos?${params}`);

export const getBroadcasts = ({ params = '' }) => http.get(`${API_VERSION}/broadcast?${params}`);

export const getSchedulesCalendarCount = ({ params = '' }) =>
  http.get(`${API_VERSION}/schedules/calendar/count?${params}`);

export const getTimezones = ({ params = '' }) => http.get(`${API_VERSION}/timezones?${params}`);

export const getSchedules = ({ params = '' }) => http.get(`${API_VERSION}/schedules?${params}`);

export const emailReminderHistory = ({ id, params = '' }) =>
  http.get(`${API_VERSION}/schedules/${id}/email-reminder-history?${params}`);

export const getRecurringSchedulesDates = ({ params = '' }) =>
  http.get(`${API_VERSION}/schedules/recurring-dates?${params}`);

export const getMeetingsRooms = ({ params = '' }) => http.get(`${API_VERSION}/meetings/rooms?${params}`);

export const getSingleSessionDetails = ({ params = '' }) =>
  http.get(`${API_VERSION}/sessions/documents/list?${params}`);

export const uploadSingleSessionDoc = ({ data }) => http.post(`${API_VERSION}/sessions/documents/upload`, data);

export const skipTodo = ({ data }) => http.post(`${API_VERSION}/todos/skip`, data);

export const addSchedule = ({ data }) => http.post(`${API_VERSION}/schedules`, data);

export const updateSchedule = ({ id, data }) => http.put(`${API_VERSION}/schedules/${id}`, data);

export const deleteSchedule = ({ id, data }) =>
  http.delete(`${API_VERSION}/schedules/${id}`, {
    data,
  });

export const schedule24HourCancel = ({ id, data }) => http.post(`${API_VERSION}/schedules/${id}/cancel`, data);

export const validateDate = ({ params }) => http.get(`${API_VERSION}/schedules/validate-date?${params}`);

export const getMeetingUrl = ({ id, params = '' }) =>
  http.get(`${API_VERSION}/schedules/meeting/${id}${params ? `?${params}` : ''}`);

export const getGlobalAssessmentTests = ({ params = '' }) =>
  http.get(`${API_VERSION}/global-assessment/tests?${params}`);

export const getGlobalAssessmentBooking = ({ params = '' }) =>
  http.get(`${API_VERSION}/global-assessment/exam-bookings?${params}`);

export const globalAssessmentExamBooking = ({ data }) =>
  http.post(`${API_VERSION}/global-assessment/exam-bookings`, data);

export const globalAssessmentExamBookingAction = ({ data, id }) =>
  http.post(`${API_VERSION}/global-assessment/exam-bookings/${id}`, data);
